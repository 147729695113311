<template lang="pug">
  .inventory-groups-settings-wrapper
    .top-bar-wrapper
      TopBar(
        :filters="filters"
        :is-add-new-disabled="isAddNewDisabled"
        :is-save-disabled="isSaveDisabled"
        :has-edit-permission="hasEditPermission()"
        @add-new="addNew"
        @save="save"
        @change-car-types="handleSelectCarTypes($event)"
        @update-search-value="handleSearch($event)"
      )
    .inventory-groups-table-wrapper
      AppOverlayLoader(:state="loading")
      InventoryGroupsTable(
        :selected-inventory-group-index="selectedInventoryGroupIndex"
        :inventory-groups="inventoryGroups"
        :sorting-data="sorting"
        :has-edit-permission="hasEditPermission()"
        @change-inventory-group="setInventoryGroup"
        @select-inventory-group="handleSelect($event)"
        @unselect-inventory-group="handleUnselect"
        @delete-inventory-group="handleDelete"
        @sorting="changeSorting"
      )
    AppPagination(
      :current-page="pagination.current_page"
      :total="pagination.total_count"
      :per-page="pagination.per_page"
      @change-pagination-data="changePagination"
    )
</template>

<script>
  // misc
  import { appDebounce } from "@/helpers/common"
  import { every, isEqual } from "lodash-es"

  // mixins
  import withPermissions from "@/mixins/withPermissions"
  import withStoreModule from "@/mixins/withStoreModule"
  import withSorting from "@/mixins/withSorting"
  import withConfirmation from "@/mixins/withConfirmation"

  // stores
  import withInventoryGroupsStore from "@/config/store/company_system/shops_settings/inventory_groups"

  const withInventoryGroups = withStoreModule(withInventoryGroupsStore, {
    resetState: true,
    name: "companiesInventoryGroups",
    readers: {
      isDirty: "isDirty",
      inventoryGroups: "items",
      filters: "filters",
      loading: "loading",
      pagination: "pagination",
      sorting: "sorting"
    },
    actions: {
      fetchInventoryGroups: "FETCH_ITEMS",
      createInventoryGroup: "CREATE_ITEM",
      updateInventoryGroup: "UPDATE_ITEM",
      deleteInventoryGroup: "DELETE_ITEM"
    },
    mutations: {
      setDirty: "SET_DIRTY",
      addInventoryGroup: "ADD_ITEM",
      setInventoryGroup: "SET_ITEM_BY_INDEX",
      setInvalidInventoryGroup: "SET_INVALID_ITEM",
      deleteInventoryGroupByIndex: "DELETE_ITEM_BY_INDEX",
      setFilters: "SET_FILTERS",
      setPagination: "SET_PAGINATION_DATA",
      setSorting: "SET_SORTING"
    }
  })

  export default {
    components: {
      TopBar: () => import("./TopBar"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader"),
      AppPagination: () => import("@/components/elements/AppPagination"),
      InventoryGroupsTable: () => import("./InventoryGroupsTable.vue")
    },

    mixins: [withInventoryGroups, withSorting, withConfirmation, withPermissions],

    watch: {
      hasUnsavedChanges(useConfirm) {
        this.setDirty(useConfirm)
        this.setLogoutConfirm(useConfirm)
      }
    },

    created() {
      this.debouncedFetchInventoryGroups = appDebounce(() => this.fetchInventoryGroups())
    },

    async mounted() {
      this.fetchInventoryGroups()
    },

    data() {
      return {
        initialSelectedInventoryGroup: null,
        selectedInventoryGroupIndex: null
      }
    },

    beforeRouteLeave(to, _from, next) {
      this.beforeRouteLeaveHandler({
        to,
        next,
        isChanges: this.hasUnsavedChanges,
        exitHandler: () => this.setDirty(false)
      })
    },

    methods: {
      addNew() {
        this.addInventoryGroup({ code: null, name: null })
        this.handleSelect(0)
      },

      save() {
        const inventoryGroup = this.inventoryGroups[this.selectedInventoryGroupIndex]
        this.handleSave(inventoryGroup)
          .then(() => {
            this.unselectInventoryGroup()
          })
          .catch(() => {
            this.setInvalidInventoryGroup(this.selectedInventoryGroupIndex)
          })
      },

      handleSave(inventoryGroup) {
        return inventoryGroup.id ? this.updateInventoryGroup(inventoryGroup) : this.createInventoryGroup(inventoryGroup)
      },

      handleUnselect() {
        if (this.selectedInventoryGroupIndex !== null) {
          this.withConfirmAction(() => {
            if (this.selectedInventoryGroup.id) {
              this.restoreSelectedInventoryGroup()
            } else {
              this.deleteInventoryGroupByIndex(this.selectedInventoryGroupIndex)
            }
            this.unselectInventoryGroup()
          })
        }
      },

      unselectInventoryGroup() {
        this.initialSelectedInventoryGroup = null
        this.selectedInventoryGroupIndex = null
      },

      restoreSelectedInventoryGroup() {
        this.setInventoryGroup({
          item: { ...this.initialSelectedInventoryGroup },
          index: this.selectedInventoryGroupIndex
        })
      },

      handleSelect(index) {
        this.selectedInventoryGroupIndex = index
        this.initialSelectedInventoryGroup = { ...this.inventoryGroups[index] }
      },

      handleDelete(index) {
        const { id } = this.inventoryGroups[index]
        if (id) {
          this.$confirm({
            title: this.$t("company_system.shop_settings.delete_inventory_group_confirmation"),
            resolve: {
              handler: () => {
                this.deleteInventoryGroup(id)
              }
            }
          })
        } else {
          this.deleteInventoryGroupByIndex(index)
        }
        this.unselectInventoryGroup()
      },

      handleSearch(value) {
        this.withConfirmAction(() => {
          this.unselectInventoryGroup()
          this.setFilters({ ...this.filters, searchValue: value })
          this.setPagination({ ...this.pagination, current_page: 1 })
          this.debouncedFetchInventoryGroups()
        })
      },

      changePagination(paginationData) {
        this.withConfirmAction(() => {
          this.unselectInventoryGroup()
          this.setPagination({ ...this.pagination, ...paginationData })
          this.debouncedFetchInventoryGroups()
        })
      },

      changeSorting(sorting) {
        this.withConfirmAction(() => {
          this.unselectInventoryGroup()
          this.setSorting(sorting)
          this.setPagination({ ...this.pagination, current_page: 1 })
          this.debouncedFetchInventoryGroups()
        })
      },

      withConfirmAction(callback) {
        this.$conditionalConfirm({
          useConfirm: this.hasUnsavedChanges,
          handler: callback
        })
      }
    },

    computed: {
      hasUnsavedChanges() {
        return (
          this.initialSelectedInventoryGroup &&
          !isEqual(this.selectedInventoryGroup, this.initialSelectedInventoryGroup)
        )
      },

      selectedInventoryGroup() {
        return this.inventoryGroups[this.selectedInventoryGroupIndex]
      },

      isUnsavedInventoryGroupExists({ inventoryGroups }) {
        return !every(inventoryGroups, "id")
      },

      isSaveDisabled() {
        return !this.selectedInventoryGroup?.name
      },

      isAddNewDisabled() {
        return this.isUnsavedInventoryGroupExists || !this.isSaveDisabled
      }
    }
  }
</script>

<style lang="sass" scoped>

  .inventory-groups-table-wrapper
    position: relative
    margin-top: 16px
</style>
