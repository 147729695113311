import { setStatePayload } from "@/config/store/StoreItemsModule/helpers"
import { cloneDeep } from "lodash-es"

export const extractCommonMutations = () => ({
  SET_DIRTY: setStatePayload("isDirty"),

  SET_ITEM: (state, item) => (state.item = item)
})

export const extractInitialItemMutations = () => ({
  SET_INITIAL_ITEM: (state, item) => (state.initialItem = item),

  RESET_ITEM: state => (state.item = cloneDeep(state.initialItem))
})

export const buildCommonState = () => ({
  isDirty: false,
  item: {}
})
